import SERVICE_URL, { LOG_TYPE } from "../config/API_DATA"
import HttpPost from "../http/httppost.js";
import Logger from './logger.js';
import {FILE_NAME} from "../config/API_DATA";
export default class GetCaptchaList {

    static async getResponse() {
        let returnValue = null;
        try {
            returnValue = await HttpPost.makeHttpPostRequest(SERVICE_URL.GET_CAPTCHA);
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_GET_CAPTCHA_LIST, "error in getting Response from  GetCAPTCHAList api ", err.toString(), "getResponse", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }
}