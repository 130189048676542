import SERVICE_URL from "../../config/API_DATA"
import Logger from '../logger.js';
import {
    FILE_NAME,
    LOG_TYPE
} from "../../config/API_DATA";
import Apollo from "../../graphql/ApolloClient";
import gql from "graphql-tag";

export default class ApiDiyPostDetailsById {
    static buildRequest(id) {
        let returnValue = null;
        try {
            let query = gql `{
                diy_project_project(where: {id: {_eq: ${id}}}) {
                  description
                  duration
                  field_of_work
                  id
                  isvideo
                  link
                  mainimage
                  required_items
                  timeinmins
                  title
                  category {
                    categoryid
                    categoryname
                  }
                }
              }
              
              `;
            returnValue = query;
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_GET_ALL_DIY_POST, "error in building body for GetAllUser api", err.toString(), "buildRequest", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }

    static async getResponse(pReqOptions) {


        let returnValue = null;
        try {
            returnValue = await Apollo.makeApolloQueryRequest(SERVICE_URL.GET_ALL_DIY_POST, pReqOptions);
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_GET_ALL_DIY_POST, "error in getting Response from GetAllUser api ", err.toString(), "getResponse", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }
}