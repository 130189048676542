<template>
  <div :class="{'mf-alert-popup delete ':status==0, 'mf-alert-popup warning':status==2,'mf-alert-popup ':status==1 }">
    <CModal
      :show.sync="show"
      :backdrop="backdrop"
      :closeOnBackdrop="closeOnBackdrop"
      :centered="centered"
      :size="size"
      :color="color"
    >
      <template #header-wrapper>
        <div
          v-if="status == 1"
          class="d-flex justify-content-center popup-icon">
          <!-- <div class=""> -->
          <template>
            <CCardHeader v-show="isShowIcon">
              <!-- <CRow></CRow> -->
              <CRow>
                <img
                  class="success-popup-icon"
                  src="@imgPath/img/svg/icons/tick.svg"/>
                <!-- <CIcon color="white" name="cil-check" size="lg" /> -->
              </CRow>
            </CCardHeader>
          </template>
          <br />
          <br />
          <!-- </div> -->
        </div>


         <div
          v-else-if="status == 2"
          class="d-flex justify-content-center popup-icon "
        >
          <!-- <div class=""> -->
          <template>
            <CCardHeader v-show="isShowIcon">
              <!-- <CRow></CRow> -->
              <CRow>

                <img
                  class="success-popup-icon"
                  src="@imgPath/img/svg/icons/exclamation-mark.svg" style=" width: 8px;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 20px;"

                />
                <!-- <CIcon color="white" name="cil-check" size="lg" /> -->
              </CRow>
            </CCardHeader>
          </template>
          <br />
          <br />
         </div>
        <div v-else class="d-flex justify-content-center popup-icon">
          <template>

             <CCardHeader v-show="isShowIcon">
              <!-- <CRow></CRow> -->
              <CRow>
                <img
                  class="success-popup-icon"
                  src="@imgPath/img/svg/icons/cross-radius.svg"
                />
                
                <!-- <CIcon color="white" name="cil-check" size="lg" /> -->
              </CRow>
            </CCardHeader>
          </template>
          <br />
          <br />
        </div>
      </template>

      <div class="d-flex justify-content-center alert-msg">
        <strong v-if ='status==2' style="text-align: center" class="pre-formatted ">
          {{infoTitle?infoTitle:$t("cAlertModal.confirmatiom")}}
         
        </strong>

        <strong v-else  style="text-align: center" class="pre-formatted "
          >{{status==1?$t("cAlertModal.success"):failedTitle?failedTitle:$t("cAlertModal.failed")}}</strong
        >
       
        <p v-if="desc&&(desc.constructor==String)" style="color: #afaeb9;">{{desc}}</p>
        <div v-if="desc&&(desc.constructor==Array)">
        <p  v-for="(description,index) in desc" :key="index" style="color: #afaeb9;">{{description}}</p>

        </div>

          <div v-if="desc&&desc.hasOwnProperty('valueList')" style="color: #afaeb9;">
          <p v-for="(value,index) in desc.valueList" :key="index">{{value}}</p> 
           </div>

         <div v-if="desc&&desc.hasOwnProperty('codeList')" style="color: #afaeb9;">
        <span>{{$t("cAlert.errorCode")+' : '}}</span>  <span v-for="(code,index) in desc.codeList" :key="index">{{code +' '}}</span> 
           </div>

      </div>
      <br />

      <div class="d-flex justify-content-center">
        {{ nextLine }}
        <div v-if="slotFunct">
          <u>
            <a style="color:Blue;" @click="slotFunct">{{ customSlot }}</a>
          </u>
        </div>
      </div>

      <template #footer-wrapper>
        <!-- <div v-if="buttonList.length==1"> 
             
              
        <CCardBody class="d-flex justify-content-center">
          <CRow>
               
            <CCol v-for="(button,index) in buttonList" :key="index" xl class="mb-3 mb-xl-0">
              <CButton
                @click="button.func"
                :color="button.color"
                aria-pressed="true"
              >{{button.name}}</CButton>
            </CCol>
          </CRow>
        </CCardBody>
       </div>
       <div v-else> -->
           <CCardBody class="d-flex justify-content-end pt-0px pl-0px pr-0px">
          <CRow class="confirm-btn-wrapper" v-if="showBtnList"> 
               
            <CCol  v-for="(button,index) in buttonList" :key="'btn'+index" xl class="removewrap mb-3 mb-xl-0">
              <CButton 
                @click.once.stop=" button.func?button.func((button &&button.param!=null)?button.param:null ):null"
               
                aria-pressed="true"
                class="btn action-c-btn btn-success m-auto bg-success-ext"
                >{{ button.name }}</CButton
              >
            </CCol>
          </CRow>
        </CCardBody>
        <!-- </div> -->
        <!-- </div>  -->
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  name: "cAlertModal",
  data: () => ({
    showBtnList : false
  }),
  props: {
    show: {
      type: Boolean,
      default: false
    },
    customSlot: {
      type: String,
      default: null
    },
    slotFunct: {
      type: Function
    },
    backdrop: {
      type: Boolean,
      default: true
    },
    infoTitle:{
      type: String,
      default: null
    },
    failedTitle:{
       type: String,
      default: null
    },

    closeOnBackdrop: {
      type: Boolean,
      default: false
    },
    centered: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: "lg"
    },

    color: {
      type: String,
      default: "dark"
    },
    nextLine: {
      type: String,
      default: ''
    },
    desc: {
      type: String|Array,
      default: ""
    },
    status: {
      type: Number,
      default: 0
    },
    isShowIcon: {
      type: Boolean,
      default: true
    },

    buttonList: {
      type: Array,
    //    default: []
       default: null,

    }
  },
  created(){
    let that = this;
    setTimeout(() => {
     that.showBtnList = true;
    },100);
  }
};
</script>
