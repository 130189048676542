<template>
  <ValidationProvider tag="div" :rules="rules" :name="name" :vid="vid" v-slot="{ errors }">
        <CRow>
          <CCol col="12">
            <label>{{label}}</label>

            <div class="input-group">
              {{currency?currency:''}}
            
               <input  ref="inputField" @input="onInput" @click="onClickValue" @blur="onChangeValue"
                :autocomplete="isAutoComplete?'on':'new-password'"
                  :disabled="isDisable"
                  :type="type"
                  class="form-control"
                  v-model="currentValue"
                  :placeholder="placeholder" 
                  :class="{RightAlign : isRightAlign, 'invalid':(!rule && errors && errors[0]), 'text-uppercase':currentValue?upperCase:false}"
                             :maxlength="maxLength?maxLength:isAmountField?getMaxCharacters(currentValue):524288"
                  :readonly="readonly"
                />
              
              <span class="input-group-btn tooltipIconAlign d-flex ai-center right-pa-icon" v-if="iconName">  
                <img src="@imgPath/img/svg/icons/help.svg" alt=""  width="15px"  v-c-tooltip.hover="{
                              content:toolTipContent?toolTipContent: $t('chngPwd.toolTip'),
                            }" >                  
<!--                     
                     <CIcon 
                            :name="iconName"
                            v-c-tooltip.hover="{
                              content: $t('chngPwd.toolTip'),
                            }"
                          /> -->
              </span>
            </div>



             <div v-if="errors && errors[0]" v-getError="emitError(id!=null?id:placeholder)"
              class="invalid-feedback icon">
            <span > {{ errors[0] }}</span>
        </div>
          </CCol>
        </CRow>
   
  </ValidationProvider>
</template>
<script>
import {getMaxCharOfAmount} from "./../../util/util";
import Logger from "./../../services/logger";
import BaseConfiguration from "./../../config/base.js";

import { ValidationProvider } from "vee-validate";
import cCaptcha from "./cCaptcha";
import { mapGetters } from "vuex";
export default {
  name: "cTextInputComponent",
  components: {
    ValidationProvider,
    cCaptcha
  },
  props: {
    upperCase:{
     type:Boolean,
     default:false
    },
    toEmit:{
      type: Boolean,
      default: false
    },
    id:{
       type: String|Object,
      default: null
    },
    // showIcon:{
    //   type: Boolean,
    //   default: null
    // },
     isAutoComplete: {
      type: Boolean,
      default: true
    },
    iconName: {
      type: String,
      default: null
    },
    rule: {
      type: Boolean,
      default: false
    },
    value: {
      //type: String,
      default: ""
    },
    rules: {
      type: [String, Object],
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    vid: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: "text"
    },

    isDisable:{
      type:Boolean,
      default:false
    },
    placeholder: {
      type: String,
      default: ""
    },
    isRightAlign: {
      type: Boolean,
      default: false

    },
    isAmountField:{
       type: Boolean,
      default: false
    },
    toolTipContent:{
      type: String,
      default: null
    },
     currency:{
      type: String,
      default: null
    },
    maxLength:{
      type:Number,
      default:null
    },
    readonly:{
      type:Boolean,
      default:false
    }
  },
  data: () => ({
    currentValue: null,
    // showIcon: false
  }),
  methods: {
     emitError(pName){
                this.$emit('caughtError',pName);
     
            },
    onInput(){
  this.$emit("onInput"); 
    },
    onChangeValue(){
       this.$emit("onBlur"); 
    },
    onClickValue(value){
      this.$emit("onFocus"); 
    },
    ///if amount field to get max characters
     getMaxCharacters(pAmount) {
        try {
          if(BaseConfiguration.isDebug) console.log("getMaxCharacters");
          let retValue;
        if(pAmount){
    if(pAmount.toString().includes('.')){
     let indexOfDot=pAmount.toString().indexOf(".");
     if(indexOfDot==14){
        retValue= 17;

     } else{
            retValue=15;
     }
 }  else{
     retValue=15;
 }
        }
        
        return retValue;
        }
    
         catch (err) {
                let body = Logger.buildRequest(
                  FILE_NAME.SYSTEM_TOPUP,
                  "error in getMaxCharacters",
                  err.toString(),
                  "getMaxCharacters",
                  LOG_TYPE.ERROR
                );
                Logger.getResponse(body);
              }
         
        }, 
  },
  watch: {
    
    currentValue(value) {
      this.$emit("input", value);  
    },
    value(val) {
      if (val !== this.currentValue) {
        this.currentValue = val;
      }
    }
  },
  
  created() {
    if (this.value) {
      this.currentValue = this.value;
    }
   if(this.toEmit){
      this.$on('emitError', this.emitError /*the name of the method to call */)
   }
  },
  directives: {
  getError: {
    // directive definition
    inserted: function (el) {
      el.focus()
    }
  },
  },
  mounted(){
      this.$root.$on("toggle_text_field", (isBlur) => {
        if(isBlur){
          if(this.$refs.inputField)
          {
this.$refs.inputField.focus();

          }
        }
     
});
  }

  
};
</script>
