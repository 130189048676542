<template>
  <quillEditor v-model="editorValue" :options="editorOption" @change="onDesEditorChange($event)"></quillEditor>
</template>
<script>
import { quillEditor } from "vue-quill-editor";
import Logger from "../../services/logger";
import { LOG_TYPE } from "../../config/API_DATA";
import { COMPONENT_NAME } from "../../util/constants";
import BaseConfiguration from "../../config/base";
// import "quill/dist/quill.snow.css";
// import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
// import "quill/dist/quill.bubble.css";
import { i18n } from "../../plugins/i18n";
export default {
  name: COMPONENT_NAME.C_RICHTEXT_EDITOR,
  props: {
    selValue: {
      type: String,
      default: null
    }
  },
  components: {
    quillEditor
  },


  watch:{
    selValue(val)
    {
      this.editorValue=val
    }

  },

 
  data: () => ({
    editorValue: null,
    editorOption: {
      placeholder: i18n.tc("createHotel.descriptionPl"),
      theme: "snow",
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],
          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],
          ["clean"],
          ["link", 
          // "image", "video"
          ]
        ]
      }

   
    }
  }),
 

  created() {
    try {
      if (BaseConfiguration.isDebug) console.log("created");
      this.editorValue = this.selValue;
    } catch (err) {
      let body = Logger.buildRequest(
        COMPONENT_NAME.C_RICHTEXT_EDITOR,
        "error in created",
        err.toString(),
        "created",
        LOG_TYPE.ERROR
      );
      Logger.getResponse(body);
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onDesEditorChange({ quill, html, text }) {
      try {
        if (BaseConfiguration.isDebug) console.log("onDesEditorChange");
        let alertValue;
        let content = text.replace(/\n/gi, "");
        if (text.length == 1 && content == "") {
          alertValue = null;
         } 

         /// condition removed for all quill no initial space validation 
         //else if (text.toString().match(/(^\S.*)/g) == null) {
        //   alertValue = this.$t("createHotel.noInitialSpace");
        // }
        // else if (text.length > 1000) {
        //   this.alert.description = this.$t("createHotel.maxChar");
        // }
        else {
          alertValue = null;
        }
        setTimeout(function() {
          quill.focus();
        }, 600);
        this.$emit("getEditorText", this.editorValue, alertValue);
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_RICHTEXT_EDITOR,
          "error in onDesEditorChange",
          err.toString(),
          "onDesEditorChange",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    }
  }
};
</script>
<style scoped>
.quill-editor {
white-space: pre-line !important;
}
</style>