import SERVICE_URL from "../../config/API_DATA"
import Logger from '../logger.js';
import {
    FILE_NAME,
    LOG_TYPE
} from "../../config/API_DATA";
import Apollo from "../../graphql/ApolloClient";
import gql from "graphql-tag";

export default class ApiUpdateDiyPostDetails {
    static buildRequest(id, updateValue) {
        let returnValue = null;
        try {
            if (!updateValue) throw new Error('Error updateValue not found')
            let query = gql `mutation update_diy_project {
                update_diy_project_project(
                    where: {id: {_eq: ${updateValue.id}}},
                     _set: {
                         categoryid: ${updateValue.categoryid}, 
                         description: "${updateValue.description}",
                          duration: "${updateValue.duration}",
                           field_of_work: "${updateValue.field_of_work}", 
                           isvideo: ${updateValue.isvideo}, 
                           link: "${updateValue.link}", 
                           tags:"${updateValue.tags}",
                           mainimage: "${updateValue.mainimage}", 
                           required_items: "${updateValue.required_items}", 
                           timeinmins: ${updateValue.timeinmins},
                           title: "${updateValue.title}",
                           status: ${updateValue.status},
                           author: "${updateValue.author}",
                           groupid: ${updateValue.groupid}
                        }) {
                  affected_rows
                }
              }
              
              `;
            returnValue = query;
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_UPDATE_DIY_POST, "error in building query for UpdateDiyPostDetails api", err.toString(), "buildRequest", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }

    static async getResponse(pReqOptions) {
        let returnValue = null;
        try {
            returnValue = await Apollo.makeApolloMutationRequest(SERVICE_URL.GET_ALL_DIY_POST, pReqOptions);
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_UPDATE_DIY_POST, "error in getting Response from UpdateDiyPostDetails api ", err.toString(), "getResponse", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }
}