<template>
<!-- <ValidationProvider tag="div" :rules="rules" :name="name" :vid="vid" v-slot="{ errors }"> -->

<CRow>
 <CAlert v-if="showAlert" show color="danger">
                      <div  v-if="alertValue&&alertValue.hasOwnProperty('valueList')">
                <p  v-for="(value,index) in alertValue.valueList" :key="index">
                        {{value}}
                      </p>
                      </div>
                      <div v-if="alertValue&&alertValue.hasOwnProperty('codeList')">
                      <span>{{$t("cAlert.errorCode")+' : '}}</span><span  v-for="(code,index) in alertValue.codeList" :key="index">
                        {{code +' '}}
                      </span>
                      </div>
                            <div  v-if="alertValue&&alertValue.constructor==String">
                <p  >
                        {{alertValue}}
                      </p>
                      </div>
                      </CAlert>
    <!-- <CAlert v-if="showAlert" show color="danger">{{alertValue}}</CAlert> -->
<div class="d-flex">
    <div v-html=currentValue></div>

    <template v-if="currentValue">
        <CButton @click="newCaptcha()" class="capcha-btn">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="c-icon c-icon-lg" color="green"><path d="M12.451 9.674h8.924v-8.924h-1.5v6.276l-1.225-1.225c-3.728-3.728-9.795-3.728-13.523 0s-3.728 9.795 0 13.523c1.732 1.728 4.122 2.796 6.762 2.796s5.030-1.068 6.762-2.796l-0 0-1.061-1.061c-3.144 3.144-8.259 3.144-11.402 0s-3.144-8.259-0-11.402 8.259-3.144 11.402 0l1.313 1.313h-6.452z"></path></svg>

        </CButton>
    </template>
</div>


</CRow>

<!-- </ValidationProvider> -->
</template>

<script>
import {
    ValidationProvider
} from "vee-validate";
import {
    mapActions
} from "vuex";
import {
    mapGetters
} from "vuex";
import apiGetCaptchaList from '../../services/apiGetCaptchaList.js'
import Logger from '../../services/logger';
import {
    LOG_TYPE,
    FILE_NAME,
    HTTP_CODES
} from '../../config/API_DATA'
import {
    VALIDATE_SUCCESS_RES,
    getErrorMessage
} from "@/util/apiValidate.js";
import {
    handleJWT,
    getErrorAlertValue
} from "../../util/util";
export default {
    name: "cCaptcha",
    components: {
        ValidationProvider
    },
    props: {
        iconName: {
            type: String,
            default: null
        },
        value: {
            type: String,
            default: ""
        },

    },
    data: () => ({
        showAlert: false,
        alertValue: null,
        currentValue: null,

    }),
    methods: {
        ...mapActions("sCaptcha", ["SET_CAPTCHA_RES"]),
        ...mapActions("sCaptcha", ["SET_CAPTCHA_TEXT"]),
        //Get Captcha Response
        async getCaptchaRes() {

            let retValue;
            try {
                let captchaResponse = await apiGetCaptchaList.getResponse();
                if (VALIDATE_SUCCESS_RES && captchaResponse.data.data.data) {
                    this.SET_CAPTCHA_RES(captchaResponse);
                    //save res in store
                    captchaResponse.data.data.text ? this.SET_CAPTCHA_TEXT(captchaResponse.data.data.text) : this.SET_CAPTCHA_TEXT(null);
                    retValue = captchaResponse.data.data.data;
                } else {
                    this.showAlert = true;

                    let error = getErrorMessage(captchaResponse);

                    let isJwt = handleJWT(error);
                    if (!isJwt) {
                        this.alertValue = getErrorAlertValue(error)
                    }
                }

            } catch (err) {
                // console.log("error");
                let body = Logger.buildRequest(FILE_NAME.AGENT_REGISTRATION, "error in getting response from GettingBotByCustomerId API", err.toString(), "getCountryListRes", LOG_TYPE.ERROR);
                Logger.getResponse(body);
            }
            return retValue;
            // console.log(this.captchaRes);
        },
        async newCaptcha() {
            this.SET_CAPTCHA_RES(null);

            let captchaList = await this.getCaptchaRes();
            this.currentValue = captchaList;
        }
    },
    computed: {
        ...mapGetters("sCaptcha", {
            captchaRes: "GET_CAPTCHA_RES",

        }),

    },
    watch: {
        currentValue(value) {
            this.$emit("input", value);
        },

       

    },

    created: async function () {
        let captchaList = await this.getCaptchaRes();
        this.currentValue = captchaList;
        //  console.log(captchaList);
    }

};
</script>


